import {
  // * TEAMS
  umar,
  daniel,
  ibro,
  // * PROJECTS
  kadcity,
  kadcityBanner,
  xanda,
  xandaBanner,
  guest,
  guestBanner,
  dcsel,
  dcselBanner,
  logoCollection,
  logoBanner,

  // * WHITE VARIATION
  white1,
  white2,
  white3,
  white4,
  white5,
  white6,
  white7,
  white8,
  white9,
  white10,
  white11,
  white12,
  white13,
  white14,
  white15,
  white16,
  white17,
  white18,

  // * BLACK VARIATION
  black1,
  black2,
  black3,
  black4,
  black5,
  black6,
  black7,
  black8,
  black9,
  black10,
  black11,
  black12,
  black13,
  black14,
  black15,
  black16,
  black17,
  black18,

  // * COLOR VARIATION
  color1,
  color2,
  color3,
  color4,
  color5,
  color6,
  color7,
  color8,
  color9,
  color10,
  color11,
  color12,
  color13,
  color14,
  color15,
  color16,
  color17,
  color18,

  // * PRESENTATION
  kadTop,
  kadMid,
  kadBot,
  kadBot1,
  kadBot2,
  xandaTop,
  xandaMid,
  xandaBot,
  xandaBot1,
  xandaBot2,
  dcselTop,
  dcselMid,
  dcselBot,
  dcselBot1,
  guestTop,
  guestMid,
  guestBot,
  guestBot1,
  guestBot2,

  // * extra logo
  macbook,
  kadLogo1,
  kadLogo2,
  kadLogo3,
  kadLogo4,
  xandaLogo1,
  xandaLogo2,
  xandaLogo3,
  xandaLogo4,
  dcselLogo1,
  dcselLogo2,
  dcselLogo3,
  dcselLogo4,
  guestLogo1,
  guestLogo2,
  guestLogo3,

  // stationaries
  vehicle1,
  vehicle2,
  vehicle3,
  xStationTop1,
  xStationTop2,
  xStationTop3,
  xStationTop4,
  xStationTop5,
  xStationTop6,
  companyProfile,
  dcselStationTop1,
  dcselStationTop2,
  dcselStationTop3,
  dcselStationBottom1,
  dcselStationBottom2,
  dcselStationBottom3,
  dcselStationBottom4,
  dcselStationBottom5,
  dcselStationBottom6,
  xStationBottom1,
  xStationBottom2,
  xStationBottom3,
  xStationBottom4,
  xStationBottom5,
  xStationBottom6,
} from "assets";

export const team = [
  {
    name: "Salihu I. Umar",
    role: "Founder, Creative Director",
    image: umar,
  },
  {
    name: "Daniel Yohanna",
    role: "Partner, Product & Graphic Design",
    image: daniel,
  },
  {
    name: "Salihu Ibrahim",
    role: "Partner, Design",
    image: ibro,
  },
];

export const projects = [
  {
    _id: 1,
    projectName: "KadCity App",
    showcase: kadcity,
    banner: kadcityBanner,
    type: "Brand Identity, & Product Design",
    client: "Kaduna Capital Territory Authority (K.C.T.A.)",
    about: [
      "KadCity app is an idea bore by one of the mayors of kaduna state, an initiative that seeks to involve the kaduna state citizens in the development of the state. It seeks to bridge the gap between the government and its people by seeing the city through the people’s eyes.",
    ],
    goals: [
      "The goal is to create a brand identity that resonates with the people and exemplifies the essence of the brand. A simple, out of the box, yet classy and edgy design that speaks creativity and sends the message across to the people of kaduna state.",
    ],
    presentation: {
      top: kadTop,
      mid: kadMid,
      bot: kadBot,
      bot1: kadBot1,
      bot2: kadBot2,
    },
    concept:
      "The concept stems from the idea of having the citizens be on the lookout for abnormalities in the city. Having them involved in governance gives them a “watchman” tag. This inspired the eye symbol incorporated into the logo design.",
    color_selection:
      "The color selection for the brand is loosely based off of Kaduna state’s official colors and strictly adhere’s to KCTA’s brand colors. This is to keep a sense of consistency with state related projects.",
    extraLogo: [kadLogo1, kadLogo2, kadLogo3, kadLogo4],
    product_design_image: macbook,
    product_design:
      "Here is the landing page for the app. The project is still in progress hence we cant share the full case study. This part will be updated upon launch of the project.",
  },
  {
    _id: 2,
    projectName: "Xanda Farms",
    showcase: xanda,
    banner: xandaBanner,
    type: "Brand Identity, Merch, & Apparel",
    client: "Xanda Farms",
    about: [
      "Xanda Farms is an Agric-business centred on livestock and crop farming. It aims to be a top distributor of agricultural produce and livestock in Nigeria. For a start, its focus is on dairy farming.",
      "Existing data on the dairy industry revealed that Nigeria produces 600,000 tonnes of milk per year, but it consumes 1.7m tonnes – leading to milk imports of $480m a year to meet local demands. The data also pointed at the need to improve production and that by 2050, Nigeria will have the fifth biggest population in the world, 400m, and there is scope for dairy products to grow as the population increases.",
      "To this end, Xanda Farms intends to deploy advanced agricultural machineries and cutting-edge IT infrastructure for its zero-grazing method of livestock farming thereby saving the local dairy industry currently driven by unsustainable methods and traditions. Our commitment is to our cows, our host communities and the environment while producing fresh, high-quality milk that is processed into different dairy products consumers enjoy every day.",
    ],
    goals: [
      "The goal of the brand identity is to embody the core of what Xanda Farms is. A logo design that expresses and carries the values and long-term goals of the brand.",
      "Using keywords like Defend, Protect, Passion, Dairy, Traditional and Modern, a design concept was created.",
    ],
    presentation: {
      top: xandaTop,
      mid: xandaMid,
      bot: xandaBot,
      bot1: xandaBot1,
      bot2: xandaBot2,
    },
    color_selection:
      "The colour selections of the identity were drawn from the industry standard and also the goals of the brand. Colours that psychologically represents some keywords were also selected to use. Different shades of green were selected and other colours to complement.",
    extraLogo: [xandaLogo1, xandaLogo2, xandaLogo3, xandaLogo4],
    stationariesTop: [
      xStationTop1,
      xStationTop2,
      xStationTop3,
      xStationTop4,
      xStationTop5,
      xStationTop6,
    ],
    stationary:
      "All stationery materials to be branded. From books, pens, business cards, letterheads and the likes.",
    stationariesBottom: [
      xStationBottom1,
      xStationBottom2,
      xStationBottom3,
      xStationBottom4,
      xStationBottom5,
      xStationBottom6,
    ],
    merchandise: "All swag, and clothing materials to be branded.",
    vehicles_images: [vehicle1, vehicle2, vehicle3],
    vehicles:
      "As a farming brand, it is required to have cars, trucks and tractors. Having these vehicles branded makes for a consistent design accross all forms.",
  },
  {
    _id: 3,
    projectName: "DCSEL - Rebrand",
    showcase: dcsel,
    banner: dcselBanner,
    type: "Identity Rebranding, Animation, & Merch",
    client: "Derive Communications Systems & Engineering Limited",
    about: [
      "Derive Communications Systems & Engineering Limited is a specialist Electrical/Electronic Engineering Company that are geared towards delivering satisfaction to its clients in Electronic Security Solutions, Fire Safety and Protection Systems, Low-Voltage and Communications Solutions as well as Information Technology.",
      "It main vision is to offer technological solutions that meet tomorrow’s needs.",
      "At its core, its values are: Dilligence, Excellence, Result, Innovation, Value and Efficiency.",
    ],
    goals: [
      "The goal of the new brand identity is to embody the core values and vision of the brand. By retaining the existing color pallete of the brand, i came up with concepts that are in line with said values.",
      "The following keywords are the characteristics of the concepts created.",
      "Modern, Technology, Engineering, Excellence, Efficiency, Satisfaction, Future.",
    ],
    presentation: {
      top: dcselTop,
      mid: dcselMid,
      bot: dcselBot,
      bot1: dcselBot1,
    },
    color_selection:
      "As requested by the client, the existing colour pallette of the brand were retained.",
    extraLogo: [dcselLogo1, dcselLogo2, dcselLogo3, dcselLogo4],
    company_profile_image: companyProfile,
    company_profile:
      "A rebranded company profile showing the core of DCSEL, its mission, vision and values. It shows its customer base and contact info. This document can serve as a pitch deck to introduce the brand.",
    stationariesTop: [dcselStationTop1, dcselStationTop2, dcselStationTop3],
    stationary:
      "All stationery materials to be branded. From books, pens, business cards, letterheads and the likes.",
    stationariesBottom: [
      dcselStationBottom1,
      dcselStationBottom2,
      dcselStationBottom3,
      dcselStationBottom4,
      dcselStationBottom5,
      dcselStationBottom6,
    ],
    merchandise: "All swag, and clothing materials to be branded.",
  },
  {
    _id: 4,
    projectName: "Guest 365",
    showcase: guest,
    banner: guestBanner,
    type: "Brand Identity Design",
    client: "Guest 365",
    about: [
      "Upon recognizing the growing need in the hospitality industry which has evolved since the COVID19 pandemic started, Guest365 was built based on our expertise and understanding of the challenges with running a hospitality businesses and delivering guest satisfaction.",
      "We have successfully built a hospitality brand that offers short term accommodations that guests love and always come back to. The lessons learned in offering great experiences to guests from all around the world, who booked their stay from different platforms, has helped us automate most of our processes to achieve guest satisfaction.",
      "Today, guests are expecting even better experiences!",
      "Our plan is to enable the industry with a solution that suits both small and large operators so they can save time and offer the best guest experience.",
    ],
    goals: [
      "The goal of the brand identity was to embody the core of what Guest365 is. A hospitality platform that puts guests first. The logo design seeks to express quality experiences.",
      "Using keywords like Modern, Quality, Automate, and Tech, the design concept was created.",
    ],
    presentation: {
      top: guestTop,
      mid: guestMid,
      bot: guestBot,
      bot1: guestBot1,
      bot2: guestBot2,
    },
    extraLogo: [guestLogo1, guestLogo2, guestLogo3],
    mockups:
      "This shows how the identity will be represented in real life. Signage and if printed on paper.",
  },
  {
    _id: 5,
    projectName: "Logo Collection - 2022",
    banner: logoBanner,
    showcase: logoCollection,
    type: "Logo Design & Branding",
    about: [
      "A collection of logo designs. Each properties and aesthetics is subject to the project briefs. ",
    ],
    variation: true,
    white: [
      white1,
      white2,
      white3,
      white4,
      white5,
      white6,
      white7,
      white8,
      white9,
      white10,
      white11,
      white12,
      white13,
      white14,
      white15,
      white16,
      white17,
      white18,
    ],
    black: [
      black1,
      black2,
      black3,
      black4,
      black5,
      black6,
      black7,
      black8,
      black9,
      black10,
      black11,
      black12,
      black13,
      black14,
      black15,
      black16,
      black17,
      black18,
    ],
    colored: [
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8,
      color9,
      color10,
      color11,
      color12,
      color13,
      color14,
      color15,
      color16,
      color17,
      color18,
    ],
  },
];

export const offers = [
  {
    title: "Branding & Identity",
    list: [
      "Brand Strategy",
      "Brand Guidelines",
      "Visual Identity",
      "Logo Design and Animation",
      "Motion Design",
      "Graphic Design",
      "Packaging Design",
    ],
  },
  {
    title: "Product Design & Development",
    list: [
      "User Interface & Experience",
      "Design Systems",
      "Mobile Applications",
      "Web Design and Development",
      "Interaction Design",
      "Prototyping",
      "Usability Testing",
      "User research",
    ],
  },
  {
    title: "Brand Engagement",
    list: [
      "Campaign and Ad Development",
      "Editorial Design",
      "Event Branding",
      "Social Media Marketing",
      "Content Strategy",
      "Copywriting",
    ],
  },
];
